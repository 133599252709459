import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import LayoutWithImageBanner from '../components/layouts/LayoutWithImageBanner';
import SmallVerticalCard from '../components/SmallVerticalCard';
import ContentReverseTitleRight from '../components/ContentReverseTitleRight';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import LargeHorizontalCard from '../components/LargeHorizontalCard';
import CardProduct from '../components/CardProduct';

const SmallVerticalCardLayout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    margin:7% 0%;
`;

const SmallVerticalCardHover = styled(CardProduct)`  
    display: flex;
    flex-direction: row;
    width: 25%;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 20%;
        margin-right: 5%;
        margin-top: 5%;
    }

    @media (min-width: 480px) and (max-width: 768px) {
        width: 40%;
        margin-right: 10%;
        margin-top: 4%;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        width: 45%;
        margin-right: 5%;
        margin-top: 4%;
    }
`;

const VerticalCardHoverLayout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 70px;

    @media (min-width: 320px) and (max-width: 768px) {
        flex-wrap: wrap;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        justify-content: flex-start !important;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        justify-content: left;
    }
`;

const LargeHorizontalCardLayout = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    margin: 7% 0%;

    @media (min-width: 320px) and (max-width: 480px) {
        flex-direction:column;
        width:100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        width:100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width:100%;
    }
`;

const LargeHorizontalCardTitle = styled.h1`
    font-size: 30px;
    font-weight: 200;
    padding-top: 3%;

    @media (min-width: 320px) and (max-width: 1024px) {
        font-size: 24px;
        font-weight: 200;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        font-size: 24px;
        font-weight: 200;
    }
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('homePage');

    const right = [
        {textRight: t('DESCRIPTION_INDOOR_OUTDOOR')},
        {
            url: '/images/products/wemap-ar/immersive-experience.svg',
            text: t('ICON_IMMERSIVE_EXPERIENCE')
        },
        {
            url: '/images/products/wemap-ar/navigation.svg',
            text: t('ICON_AR_POINT')
        },
        {
            url: '/images/products/wemap-ar/indoor-outdoor.svg',
            text: t('ICON_INDOOR_OUTDOOR')
        }
    ];


    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_HOME')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_HOME')}/>
            </Helmet>
            <LayoutWithImageBanner image={data.heroBanner.childImageSharp}>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_RICH_EXPERIENCE')}
                        cellParagraph={t('DESCRIPTION_PLATFORM_WEMAP')}
                        altDescription='mobiles with outdoor augmented reality navigation and open pinpoint'
                        pictureRight={data.arNav.childImageSharp}
                        titleButton={t('TITLE_BUTTON_DEMO')}
                        urlFooter='/contact/'
                        phraseLogo={t('TITRE_LOGO_INDUSTRY')}
                        customersLogos={[
                            {
                                url: '/sport/',
                                image: '/images/industries/events/fff.png'
                            },
                            {
                                url: '/medias/',
                                image: '/images/products/wemap-web/ouest-france.svg'
                            },
                            {
                                url: '/government/',
                                image: '/images/home/logo-ministere-culture.png'
                            },
                            {
                                url: '/culture/',
                                image: '/images/products/wemap-ar/hachette-tourisme.svg'
                            },
                            {
                                url: '/games/',
                                image: '/images/products/games/qh-aventures.svg'
                            }
                        ]}
                    />

                    <SmallVerticalCardLayout>
                        <SmallVerticalCard headerImageUrl='/images/home/white_circle-media.svg'
                            headerImageBlueUrl='/images/home/circle-media.svg'
                            title={t('TITLE_CARD_MEDIA')}
                            text={t('DESCRIPTION_CARD_MEDIA')}
                            buttonText={t('BUTTON_CARD_LEARN_MORE')}
                            buttonUrl='/medias/'
                            footerImage={data.footer1.childImageSharp}
                            footerImageAlt="Compagnies using wemap" />

                        <SmallVerticalCard headerImageUrl='/images/home/white_circle-travel.svg'
                            headerImageBlueUrl='/images/home/circle-travel.svg'
                            title={t('TITLE_CARD_TRAVEL')}
                            text={t('DESCRIPTION_CARD_TRAVEL')}
                            buttonText={t('BUTTON_CARD_LEARN_MORE')}
                            buttonUrl='/travel/'
                            footerImage={data.footer2.childImageSharp}
                            footerImageAlt="Compagnies using wemap" />

                        <SmallVerticalCard headerImageUrl='/images/home/white_circle-local-authorities.svg'
                            headerImageBlueUrl='/images/home/circle-local-authorities.svg'
                            title={t('TITLE_CARD_LOCAL_AUTHORITIES')}
                            text={t('DESCRIPTION_CARD_LOCAL_AUTHORITIES')}
                            buttonText={t('BUTTON_CARD_LEARN_MORE')}
                            buttonUrl='/government/'
                            footerImage={data.footer3.childImageSharp}
                            footerImageAlt="Compagnies using wemap" />

                        <SmallVerticalCard headerImageUrl='/images/home/white_circle-event.svg'
                            headerImageBlueUrl='/images/home/circle-event.svg'
                            title={t('TITLE_CARD_EVENT')}
                            text={t('DESCRIPTION_CARD_EVENT')}
                            buttonText={t('BUTTON_CARD_LEARN_MORE')}
                            buttonUrl='/events/'
                            footerImage={data.footer4.childImageSharp}
                            footerImageAlt="Compagnies using wemap" />
                    </SmallVerticalCardLayout>

                    <ContentReverseTitleRight
                        title={t('TITLE_INDOOR_OUTDOOR')}
                        altDescription='mobiles with indoor augmented reality navigation and open pinpoint in store'
                        leftIcon={data.wemapAr.childImageSharp}
                        widthPicture='550px'
                        titleButton={t('BUTTON_INDOOR_OUTDOOR')}
                        right={right}
                    />

                    <VerticalCardHoverLayout>
                        <SmallVerticalCardHover
                            headerImageUrl='/images/home/icon_wemapwebmobile.svg'
                            title={t('TITLE_CARD_WEMAP_WEB_MOBILE')}
                            text={t('DESCRIPTION_CARD_WEMAP_WEB_MOBILE')}
                            buttonText={t('BUTTON_CARD_LEARN_MORE')}
                            buttonUrl='/web-mobile/' />

                        <SmallVerticalCardHover
                            headerImageUrl='/images/products/icon_indoor.svg'
                            title={t('TITLE_CARD_WEMAP_INDOOR')}
                            text={t('DESCRIPTION_CARD_WEMAP_INDOOR')}
                            buttonText={t('BUTTON_CARD_LEARN_MORE')}
                            buttonUrl='/indoor-maps/' />

                        <SmallVerticalCardHover
                            headerImageUrl='/images/products/icon_wemapar.svg'
                            title={t('TITLE_CARD_WEMAP_AR')}
                            text={t('DESCRIPTION_CARD_WEMAP_AR')}
                            buttonText={t('BUTTON_CARD_LEARN_MORE')}
                            buttonUrl='/augmented-reality/' />

                        <SmallVerticalCardHover
                            headerImageUrl='/images/products/icon_wemapgame.svg'
                            title={t('TITLE_CARD_WEMAP_GAME')}
                            text={t('DESCRIPTION_CARD_WEMAP_GAME')}
                            buttonText={t('BUTTON_CARD_LEARN_MORE')}
                            buttonUrl='/games/' />
                    </VerticalCardHoverLayout>

                    <hr className='desktop' />

                    <LargeHorizontalCardTitle>{t('WEMAP_OPEN_SYSTEM')}</LargeHorizontalCardTitle>

                    <LargeHorizontalCardLayout>
                        <LargeHorizontalCard
                            headerImageUrl='/images/products/osm_logo.jpg'
                            alt='logo of Open street map'
                            title={t('OSM_TITLE')}
                            text={t('MEMBER_OSM')}
                            textButton={t('ABOUT_PAGE')}
                            url='https://www.openstreetmap.org/' />

                        <LargeHorizontalCard
                            headerImageUrl='/images/products/open_ar_cloud.jpg'
                            alt='logo of Open AR Cloud'
                            title={t('OPEN_AR_TITLE')}
                            text={t('DESCRIPTION_CARD_WEMAP_DEVELOPERS')}
                            textButton={t('LEARN_MORE_WEMAP')}
                            url='https://www.openarcloud.org/' />
                    </LargeHorizontalCardLayout>
                </div>
            </LayoutWithImageBanner>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  arNav: file(relativePath: {eq: "home/wemap-ar-navigation-v2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 460, layout: FIXED)
    }
  }
  footer1: file(relativePath: {eq: "home/card_footer_1_logos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  footer2: file(relativePath: {eq: "home/card_footer_2_logos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  footer3: file(relativePath: {eq: "home/card_footer_3_logos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  footer4: file(relativePath: {eq: "home/card_footer_4_logos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  wemapAr: file(relativePath: {eq: "home/screen-wemap-ar.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  heroBanner: file(relativePath: {eq: "home/home-page-picture-v2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`;
