import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    GatsbyImage, getImage
} from 'gatsby-plugin-image';
import LocalizedLink from './LocalizedLink';

const ButtonSecondary = styled(LocalizedLink)`
    border-radius: 50px;
    width: auto;
    padding: 1em 2.2em;
    border: none;
    font-size:13px;
    font-weight:300;
    background-color:#227EE6;
    color:white;
    align-self: center;
    cursor: pointer;

    @media (min-width: 320px) and (max-width: 1024px){
        width:auto;
        padding: 1em 1.0em;
    }

    @media screen and (max-width: 768px) {
        display:none;
    }
`;

const LinkCard = styled.div`

    display: contents;
`;

const IconWhite = styled.img`
    width:50%;

    @media screen and (max-width: 768px) {
        display:none;
    }

`;

const IconBlueHover = styled.img`
    display: none;

    @media screen and (max-width: 768px) {
        display:flex;
        width:50%;
        margin: 0 auto;
    }
`;

const Cell = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
flex-grow: 1;
padding: 8% 13%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
`;

const Title = styled.h3`
    color:white;
    font-weight:300;
    flex-grow:1;

    @media screen and (max-width: 768px) {
        color:#434343; !important;

        &:hover{
            color:#434343;;
        }
    }
`;

const TextCell = styled.figcaption`
    font-size:14px;
    font-weight:200;
    flex-grow:1;
`;

const Text = styled.p`
    @media screen and (max-width: 768px) {
        color:#434343; !important;

        &:hover{
            color:#434343;;
        }
    }
`;

const Footer = styled.div`
    display:none;
`;

const FooterImg = styled(GatsbyImage)`
    width:100%;
`;

const Content = styled.div`
    width:250px;
    height:420px;
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-sizing:border-box;
    justify-content:space-between;

    @media screen and (max-width: 768px) {
        background-color:white;
        width: 48%;
        height: auto;
        margin: 1%;

        &:hover{
            width: 48% !important;
            height: auto !important;
        }

    }

    &:hover {
        height:420px;
        background-color: white;
        transition: 0.2s;

        ${IconBlueHover} {
        display:flex;
        width:50%;
        margin: 0 auto;
        }

        ${IconWhite} {
            display:none;
        }

        ${Footer} {
            display: flex;
            align-items: center;
            justify-content:center;
            border-top: 1px dashed #cbc7c7;
            padding: 5%;

            @media screen and (max-width: 768px) {
                display:none;

                &:hover{
                    display:none;
                }
            }

        }

        ${Title} {
            color:  #434343;
        }

        ${TextCell} {
            color:  #434343;
        }
    }
`;

const SmallVerticalCard = ({
    title,
    headerImageUrl,
    headerImageBlueUrl,
    text,
    buttonUrl,
    buttonText,
    footerImage,
    footerImageAlt
}) => {
    return (
        <LinkCard>
            <Content>
                <Cell>
                    <Header>
                        <IconWhite alt={`White icon representing ${title}`}
                            src={headerImageUrl} />
                        <IconBlueHover alt={`Blue icon representing ${title}`}
                            src={headerImageBlueUrl} />
                        <Title>{title}</Title>
                    </Header>
                    <TextCell>
                        <Text>{text}</Text>
                    </TextCell>
                    <ButtonSecondary to={buttonUrl}>
                        {buttonText}
                    </ButtonSecondary>
                </Cell>
                <Footer>
                    {footerImage
                        ? (
                            <FooterImg
                                image={getImage(footerImage)}
                                alt={footerImageAlt} />
                        )
                        : null
                    }

                </Footer>
            </Content>
        </LinkCard>
    );
};

SmallVerticalCard.propTypes = {
    title: PropTypes.string,
    headerImageUrl: PropTypes.string,
    headerImageBlueUrl: PropTypes.string,
    text: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonText: PropTypes.string,
    footerImage: PropTypes.object,
    footerImageAlt: PropTypes.string
};

export default React.memo(SmallVerticalCard);
