import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    GatsbyImage, getImage
} from 'gatsby-plugin-image';
import SecondaryButton from './SecondaryButton';
import LocalizedLink from './LocalizedLink';


const Title = styled.h3`
    font-size: 24px;
    font-weight: 300;
`;

const Line = styled.hr`
    width:100%;

`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin:7% 0%;

    @media (max-width: 767px) {
        width:80%;
        flex-direction:column;
        margin: 10% auto;
    }
`;

const IconLeft = styled(GatsbyImage)`
    width: ${(props) => props.width};

    @media (max-width: 767px) {
        width:100% !important;
    }
`;

const RightCell = styled.div`
    display: flex;
    flex-direction: column;
    width:450px;

    @media (max-width: 767px) {
        width:100%;
        margin-top: 10%;
    }

`;

const Text = styled.p`
    font-size: 14px;
    font-weight: 200;
    line-height: 21px;
    margin-top:0px;
    margin-bottom:0px;
`;

const Icon = styled.img`
    width:2vh;
    margin-right:3%;

    @media (max-width: 767px) {
        width:2vh;
        margin-right:10%;
    }
`;

const TextIcon = styled.p`
    font-size: 14px;
    font-weight: 200;
    line-height: 21px;
    margin-top:0px;
    margin-bottom:0px;
`;

const RightCells = ({
    textRight, icon, text
}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '4%'
        }}>
            <Text dangerouslySetInnerHTML={{ __html: textRight }}></Text>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '4%'
            }}>
                {icon ? <Icon src={icon}
                    alt='Representation of icon cell' />
                    : null
                }
                <TextIcon dangerouslySetInnerHTML={{ __html: text }}></TextIcon>
            </div>
        </div>
    );
};

RightCells.propTypes = {
    textRight: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string
};

const ContentReverseTitleRight = ({
    title, right, leftIcon, widthPicture, titleButton, altDescription
}) => {

    const rightCells = right.map((i, key) => (
        <RightCells
            textRight={i.textRight}
            icon={i.url}
            text={i.text}
            key={`right_${key}`}
        />));

    return (
        <React.Fragment>
            <Line />
            <Content>
                {leftIcon ? <IconLeft image={getImage(leftIcon)}
                    alt={altDescription}
                    style={{ width: `${widthPicture}` }} /> : null}

                <RightCell>
                    <Title>{title}</Title>
                    {rightCells}
                    <LocalizedLink to='/contact/'>
                        <SecondaryButton titleButton={titleButton} />
                    </LocalizedLink>
                </RightCell>
            </Content>

        </React.Fragment>
    );
};

ContentReverseTitleRight.defaultProps = {right: []};

ContentReverseTitleRight.propTypes = {
    right: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    leftIcon: PropTypes.object,
    widthPicture: PropTypes.string,
    titleButton: PropTypes.string,
    altDescription: PropTypes.string
};

export default ContentReverseTitleRight;
