import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LocalizedLink from './LocalizedLink';

const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 170px;
    color: #434343;
    border-radius: 10px;
    padding: 20% 5%;
    background-color: white;
    height: 190px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.8);
        color: #434343;
        transition: 0.5s;
    }

        @media (min-width: 320px) and (max-width: 481px) {
            height: 230px;
        }

        @media screen and (min-width: 481px) and (max-width: 768px) {
            height: 210px;
        }
`;

const IconCell = styled.div`
    width:100%;
`;

const Icon = styled.img`
    max-width: 100%;
    border-radius: 50%;
    max-width: 50%;
`;


const Description = styled.figcaption`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h3`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin: 3px 0;
    flex-grow:1;
`;

const Text = styled.p`
    font-size: 13px;
    font-weight:200;
    line-height: 24px;
`;

const CardProduct = ({
    buttonUrl, headerImageUrl, title, text, className
}) => {

    return (
        <LocalizedLink className={className}
            to={buttonUrl}>
            <Card>
                <IconCell>
                    <Icon
                        src={headerImageUrl}
                        alt='circle picture media'
                    />
                </IconCell>
                <Description>
                    <Title>{title}</Title>
                    <Text>{text}</Text>
                </Description>
            </Card>
        </LocalizedLink>
    );
};

CardProduct.propTypes = {
    buttonUrl: PropTypes.string,
    headerImageUrl: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string
};

export default React.memo(CardProduct);
