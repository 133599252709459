import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SecondaryButton from './SecondaryButton';
import LocalizedLink from './LocalizedLink';

const CardCell = styled.div`
    width: 48%;
    border-radius:10px;
    background-color:white;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;

    @media (min-width: 320px) and (max-width: 767px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom:17px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 49%;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom:17px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width: 49%;
        flex-direction: column;
        align-items: flex-start;
    }

`;

const CellIcon = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 9%;

    @media (min-width: 320px) and (max-width: 1024px) {
        width:62px;
        padding: 12% 12% 3% 12%;
        margin:0px !important;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width:62px;
        padding: 12% 12% 3% 12%;
        margin:0px;
    }

`;


const Description = styled.div`
    margin: 8%;

    @media (min-width: 320px) and (max-width: 1024px) {
        padding: 0% 12% 12% 12%;
        margin:0px !important;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        padding: 0% 12% 12% 12%;
        margin:0px !important;
    }

`;

const Title = styled.p`
    color:#FFC871;
    font-size:18px;
    font-weight:300;
    margin-top:0;
`;

const Text = styled.p`
    color:#434343;
    font-size:14px;
    line-height:26px;
`;

const LinkLearnMore = styled(LocalizedLink)`
`;


const LargeHorizontalCard = ({
    headerImageUrl,
    alt,
    title,
    text,
    url,
    textButton
}) => (
    <CardCell>
        <CellIcon>
            <img src={headerImageUrl}
                alt={alt} />
        </CellIcon>
        <Description>
            <Title>{title}</Title>
            <Text>{text}</Text>
            <LinkLearnMore to={url}>
                <SecondaryButton titleButton={textButton} />
            </LinkLearnMore>
        </Description>
    </CardCell>
);

LargeHorizontalCard.propTypes = {
    headerImageUrl: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    textButton: PropTypes.string
};

export default React.memo(LargeHorizontalCard);

